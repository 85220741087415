(function ($) {
  $(document).ready(function () {
    $.initVariable = function() {
      var $quicksign = $(".quicksign");
      var $quicksignwrap = $("#quicksignwrap");
      //append blank image
      $quicksign.each(function () {
        var thisimgsrc = $(this).find("img").attr("src");
        $(this).attr("oldImg", thisimgsrc);
      });
  
      $.getDocHeight = function () {
        var D = document;
        return Math.max(
          Math.max(D.body.scrollHeight, D.documentElement.scrollHeight),
          Math.max(D.body.offsetHeight, D.documentElement.offsetHeight),
          Math.max(D.body.clientHeight, D.documentElement.clientHeight)
        );
      };
      var bodh = $.getDocHeight();
      //append cleartext, qsdiag, qssubmit
      $quicksignwrap.append('<div id="cleartext"></div>');
      $quicksignwrap.append('<div id="qsdiag"></div>');
      $quicksignwrap.append('<div id="qssubmit"></div>');
      $quicksignwrap.css({ height: bodh });
      var dataURL, oldText, size, blur;
      var $qsdiag = $("#qsdiag");
      var $divquicksign = $("#quicksign");
      var $cleartext = $("#cleartext");
      var $qssubmit = $("#qssubmit");
      var qscolor = "#111";
      var blankURL = document.getElementById("quicksign").toDataURL();
      //qssubmit and quicksignback click event
      $("#quicksignback, #qssubmit").on("click", function () {
        $qsdiag.fadeOut(1000);
        $quicksignwrap.fadeOut(1000);
        dataURL = canvas.toDataURL("image/png");
        $(".qson").find("img").attr({ src: dataURL });
        //$('.on').css({'background-image' : 'url('+dataURL+')'});
      });
      function loadOld() {
        //get qson oldImg src redraw canvas
        var oldsrc = $(".qson").attr("oldImg");
        $(".qson").find("img").attr("src", oldsrc);
        var oldimg = new Image();
        oldimg.src = $(".qson").attr("oldImg");
  
        context.shadowBlur = 0;
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
        oldimg.onload = function () {
          context.drawImage(oldimg, 0, 0, canvas.width, canvas.height);
        };
      }
      $cleartext.on("click", function () {
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, canvas.width, canvas.height);
        //clear canvas and load oldImg
        loadOld();
      });
  
      //quicksign elements click event
      $quicksign.on("click", function () {
        var $this = $(this);
        oldText = canvas.toDataURL("image/png");
        //load element img into canvas
        var thisimg = $this.find("img").attr("src");
        var img = new Image();
        img.src = $this.find("img").attr("src");
        img.onload = function () {
          context.drawImage(img, 0, 0, canvas.width, canvas.height);
        };
        //set size and blur
        // console.log();
        var pentype = $this.attr('data-pentype');
        //console.log(pentype);
        if (pentype == "bold") {
          size = 3;
          blur = 3;
        } else if (pentype == "medium") {
          size = 2;
          blur = 1;
        } else if (pentype == "marker") {
          size = 1;
          blur = 3;
        } else if (pentype == "thin") {
          size = 0.5;
          blur = 1;
        }
        //if data-color set pencolor
        if ($this.attr('data-color')) {
          var pencolor = $this.attr('data-color');
        } else {
          var pencolor = "#000000";
        }
        qscolor = pencolor;
        context.save();
        context.restore();
        var thisy = $this.offset().top;
        var thisx = $this.offset().left;
        var thisw = $this.width();
        var thish = $this.height();
        canvas.width = thisw;
        canvas.height = thish;
        var clearw = $cleartext.width();
        var diagheight = $qsdiag.height();
        var thishhalf = thish / 2;
        $qsdiag.fadeIn({ duration: 2000 }, { queue: false });
        $qsdiag
          .css({ left: thisx, top: thisy - diagheight - 200 }, { duration: 1 })
          .animate(
            { top: thisy - diagheight - 20 },
            { duration: 1000, queue: false }
          );
  
        $divquicksign.css({
          left: thisx,
          top: thisy,
          width: thisw,
          height: thish,
        });
        $cleartext.css({ left: thisx - clearw - 10, top: thisy });
        $qssubmit.css({ left: thisx + thisw + 10, top: thisy });
  
        $quicksign.removeClass("qson");
        $this.addClass("qson");
        $quicksignwrap.fadeIn(1000);
      });
  
      var $this = $(this);
      //extend settings as options
      var canvas, context;
  
      //init function for canvas intializing
      function init() {
        // Find the canvas element.
        canvas = document.getElementById("quicksign");
        context = canvas.getContext("2d");
  
        //canvas draw tool instance
        tool = new tool_pencil();
        // Attach the mousedown, mousemove and mouseup event listeners - Draw on canvas.
        canvas.addEventListener("mousedown", ev_canvas, false);
        canvas.addEventListener("mousemove", ev_canvas, false);
        canvas.addEventListener("mouseup", ev_canvas, false);
        canvas.addEventListener("mouseout", ev_canvas, false);
        canvas.addEventListener("touchend", ev_canvas, false);
        canvas.addEventListener("touchstart", ev_canvas, false);
        canvas.addEventListener("touchmove", ev_canvas, false);
      }
  
      //call init
      init();
  
      //mobile scroll disable
      var handleMove = function (e) {
        var scrollable = false;
        var items = $(e.target).parents();
        $(items).each(function (i, o) {
          if ($(o).hasClass("scrollable")) {
            scrollable = true;
          }
        });
        if (!scrollable) e.preventDefault();
      };
  
      document
        .getElementById("quicksign")
        .addEventListener("touchmove", handleMove, true);
      //android bug fix - rendered useless!!
      document.getElementById("quicksign").addEventListener(
        "touchstart",
        function (e) {
          onStart(e);
        },
        false
      );
      function onStart(touchEvent) {
        if (navigator.userAgent.match(/Android/i)) {
          touchEvent.preventDefault();
        }
      }
  
      //func tool_pencil
      function tool_pencil() {
        var tool = this;
        this.started = false;
        // mouse actions
        this.mousedown = function (ev) {
          context.strokeStyle = qscolor;
          context.shadowColor = qscolor;
          context.shadowBlur = blur;
          context.shadowOffsetX = 0;
          context.shadowOffsetY = 0;
          context.lineWidth = size;
          context.miterLimit = 0.1;
          context.moveTo(ev._x, ev._y);
          context.beginPath();
          tool.started = true;
        };
        //end mousedown
        this.touchstart = function (ev) {
          context.strokeStyle = qscolor;
          context.shadowColor = qscolor;
          context.shadowBlur = blur;
          context.shadowOffsetX = 0;
          context.shadowOffsetY = 0;
          context.lineWidth = size;
          context.miterLimit = 0.1;
          context.moveTo(ev._x, ev._y);
          context.beginPath();
          tool.started = true;
        };
        //end touchstart
  
        //end touchstart
        this.mousemove = function (ev) {
          if (tool.started) {
            context.lineTo(ev._x, ev._y);
            context.lineCap = "round";
            context.stroke();
          }
        };
        //end mousemove
        this.touchmove = function (ev) {
          if (tool.started) {
            context.lineTo(ev._x, ev._y);
            context.lineCap = "round";
            context.stroke();
          }
        };
  
        //end touchmove
  
        this.mouseup = function (ev) {
          if (tool.started) {
            tool.started = false;
          }
        };
        //end mouseup
        //start touchend
        this.touchend = function (ev) {
          if (tool.started) {
            tool.started = false;
          }
        };
      }
      //func
      function ev_canvas(ev) {
        ev._x = ev.offsetX == undefined ? ev.layerX : ev.offsetX;
        ev._y = ev.offsetY == undefined ? ev.layerY : ev.offsetY;
  
        var func = tool[ev.type];
        if (func) {
          func(ev);
        }
      }
  
      //activate html2canvas
      $("#qssave").on("click", function () {
        $("#quicksigndoc").html2canvas({});
      });
    }
    
  });
})(jQuery);
